<template>
  <div>

    <PageHeader
        class="mt-10 mx-2 mx-sm-16"
        :titlePageHeader="titlePageHeader = 'Home'"
        :breadcrumbps="breadcrumbps"
    />
    <v-container fluid>
      <v-row class="d-flex  pa-8 ml-2">
        <v-col cols="12">
          <v-card
              class="mx-auto layout-form"
              max-width="100%"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>

                <v-list-item-title class="text-h5 mb-1">
                  <h2>   Facturado:</h2>

                </v-list-item-title>
              </v-list-item-content>

              <h1>{{formatNumber(total)}}</h1>

            </v-list-item>

          </v-card>
        </v-col>
        <v-col cols="12">
          <h1 style="text-align: center">Facturas recientes</h1>
          </v-col>
        <v-col cols="6" v-for="a in invoices" :key="a.id">
          <v-card
              class="mx-auto layout-form"
              max-width="100%"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{a.Customer.full_name}} <br>
                  {{a.date_invoice}}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{a.reference}}
                </v-list-item-title>

              </v-list-item-content>

              <h1>{{formatNumber(a.amount_total)}}</h1>

            </v-list-item>

          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>

</template>

<script>

import PageHeader from "../components/Dashboard/PageHeader";
import {mapActions} from "vuex";

export default {
  name: "Home",
  data() {
    return {
      total:0,
      invoices:[],
      breadcrumbps: [
        {
          text: "Home",
          disabled: false,
          href: "",
        },

      ],

    }
  },
  methods:{
    ...mapActions('invoices', ['findSumInvoice','findRecentInvoice']),
    async totalSum(){
      this.total = await this.findSumInvoice()

    },
    async findRecent(){
      this.invoices = await this.findRecentInvoice()

    },
  },
  async created(){
    await this.totalSum()
    await this.findRecent()
  },
  components: {
    PageHeader,
  }
}
</script>

<style>
</style>
